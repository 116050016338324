import React from "react";
import {
  createBrowserRouter,
  RouterProvider,
  createRoutesFromElements,
  Route,
  Outlet,
} from "react-router-dom";
import CampaignDetail from "../views/CampaignDetail";
import HomeView from "../views/HomeView";
import LeaderboardView from "../views/LeaderboardView";
import GoalprizeInformationView from "../views/GoalprizeInformationView";
import GoalprizeInformationDetailView from "../views/GoalprizeInformationDetailView";
import RestaurantDetail from "../views/RestaurantDetail";
import ErrorPage from "../views/ErrorView";
import NavBar from "../components/AppBar";

// Define routes using createRoutesFromElements
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      errorElement={<ErrorPage />}
      element={
        <>
          <NavBar />
          <Outlet />
        </>
      }
    >
      <Route path="/" element={<HomeView />} />
      <Route path="campaignDetail" element={<CampaignDetail />} />
      <Route path="/leaderboardView" element={<LeaderboardView />} />
      <Route
        path="/goalprizeInformationView"
        element={<GoalprizeInformationView />}
      />
      <Route
        path="/goalprizeInformationDetailView"
        element={<GoalprizeInformationDetailView />}
      />
      <Route path="restaurantDetail" element={<RestaurantDetail />} />

      <Route path="*" element={<ErrorPage />} />
    </Route>
  )
);

export default function RouteList() {
  return <RouterProvider router={router} />;
}
